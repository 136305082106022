import { isWhitelabel } from '@digital-retail/store-manager';
import { icons } from '../config/styles';
import _ from './LodashImports';
import { svgReplaceColor, svgToDataUri } from './styles/svgMixins';

export const STORE_FALLBACK_COLORS = {
  so_com: '#0072CE',
  tienda: '#ADD500',
  sodimac: '#0072CE',
  linio: '#C300A2',
  tottus: '#64A70B',
  to_com: '#19CE4D',
  homecenter: '#0072CE',
  fa_america: '#333333',
  fa_aldo: '#3300ad',
  fa_carters: '#00a9e0',
  fa_crabarr: '#333333',
};

const fallbackForEmpty = (obj, path, defaultValue) => {
  return _.get(obj, path, defaultValue);
};

const getCustomCssPropertiesStyle = (appCtx) => {
  const { store } = appCtx;
  const propertiesObj = {
    primaryColor: { key: '--primary-color', value: '#343E49' },
    secondaryColor: { key: '--secondary-color', value: '#343E49' },
    transparentPrimaryColor: {
      key: '--transparent-primary-color',
      value: '#969BA0',
    },
    primaryColorMKP: { key: '--primary-color-mkp', value: '#343E49' },
    customColor: { key: `--custom-color`, value: '#495867' },
    primaryCheckboxRebranded: {
      key: '--primary-checkbox-rebranded',
      value: `url(${svgToDataUri(icons.checkboxTickWhite)})`,
    },
    secondaryCheckboxRebranded: {
      key: '--secondary-checkbox-rebranded',
      value: `url(${svgToDataUri(icons.checkboxTickWhite)})`,
    },
    primaryRoundCheckboxRebranded: {
      key: '--primary-round-checkbox-rebranded',
      value: `url(${svgToDataUri(svgReplaceColor(icons.ellipse, '#343E49'))})`,
    },
    secondaryRoundCheckboxRebranded: {
      key: '--secondary-round-checkbox-rebranded',
      value: `url(${svgToDataUri(svgReplaceColor(icons.ellipse, '#343E49'))})`,
    },
    borderRadius: { key: '--border-radius', value: '12px' },
  };
  if (store && !appCtx.disableSISTheme) {
    const primaryColorPath = isWhitelabel({ store })
      ? 'header.entry.xlp_themes.styles.color'
      : `header.entry.themes.${store}.color`;

    const secondaryColorPath = isWhitelabel({ store })
      ? 'header.entry.xlp_themes.styles.secondary_color'
      : `header.entry.themes.${store}.secondary_color`;

    propertiesObj.primaryColor.value = fallbackForEmpty(appCtx, primaryColorPath, STORE_FALLBACK_COLORS[store]);
    propertiesObj.secondaryColor.value = fallbackForEmpty(appCtx, secondaryColorPath, '#495867');
    propertiesObj.transparentPrimaryColor.value = `${fallbackForEmpty(
      appCtx,
      primaryColorPath,
      STORE_FALLBACK_COLORS[store]
    )}85`;
    propertiesObj.customColor.value = fallbackForEmpty(appCtx, primaryColorPath, STORE_FALLBACK_COLORS[store]);
    propertiesObj.primaryCheckboxRebranded.value = `url(${svgToDataUri(icons.checkboxTickWhite)})`;
    propertiesObj.secondaryCheckboxRebranded.value = `url(${svgToDataUri(icons.checkboxRebranded)})`;
    propertiesObj.primaryRoundCheckboxRebranded.value = `url(${svgToDataUri(
      svgReplaceColor(icons.ellipse, propertiesObj.primaryColor.value)
    )})`;
    propertiesObj.secondaryRoundCheckboxRebranded.value = `url(${svgToDataUri(
      svgReplaceColor(icons.ellipse, propertiesObj.secondaryColor.value)
    )})`;
    if (isWhitelabel({ store })) {
      propertiesObj.borderRadius.value = fallbackForEmpty(
        appCtx,
        'header.entry.xlp_themes.styles.border_radius',
        '0px'
      );
    }
  }
  return `:root { ${Object.values(propertiesObj)
    .map((item) => `${item.key}: ${item.value};`)
    .join('\n')} }`;
};

export { getCustomCssPropertiesStyle };
