import { isStandalone, isTottusStandalone } from '@digital-retail/store-manager';

export const replacePathsToStandalonePaths = (pageName, store) => {
  const pathsToReplace = {
    category: 'lista',
    search: 'buscar',
    collection: 'seleccion',
    brand: 'marca',
    seller: 'vendedor',
    shop: 'b',
  };
  return isStandalone({ store }) ? pathsToReplace[pageName] : pageName;
};

export const getQuantityUnit = ({ store }) => {
  let quantityUnit = 'C/U';
  if (isTottusStandalone(store)) {
    quantityUnit = 'UNITS';
  }
  return quantityUnit;
};
