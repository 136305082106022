export const storeMap = {
  sodimac: 'sodimac',
  tottus: 'tottus',
  linio: 'linio',
  homecenter: 'homecenter',
  // Standalone
  so_com: 'sodimac',
  to_com: 'tottus',
  // Whitelabel
  fa_america: 'americanino',
  fa_carters: 'carters',
  fa_aldo: 'aldo',
  fa_crabarr: 'crateandbarrel',
};
