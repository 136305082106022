import { Footer, Header } from '@digital-retail/falabella-ui-cross-components';
import {
  CARTLINE_QUANTITIES_COOKIE_NAME,
  getCommerceHeaders,
  isSodimacStandalone,
  isStandalone,
  isTottusStandalone,
  parseCartQuantities,
} from '@digital-retail/store-manager';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import apiConfig from '../../config/api/apiConfig';
import constants from '../../config/constants';
import CookieStorage from '../../utils/CookieStorage';
import httpService from '../../utils/httpService';
import _ from '../../utils/LodashImports';
import { navigateWithUrl, redirect } from '../../utils/RouteHandler';
import { isMarketPlace } from '../../utils/urlHelper';
import UserContext from '../../utils/UserContext';
import WebStorage from '../../utils/WebStorage';
import WithUrl from '../../utils/WithUrl';
import { layoutStyle } from './Layout.style';

const { DEVICE_DESKTOP } = constants;

const LayoutContainer = ({ children, appCtx }) => {
  const router = useRouter();

  const layoutCss = layoutStyle(appCtx.tenant);
  const [cartCount, setCartCount] = useState('');
  const { refreshUserData, setTick, tick } = useContext(UserContext.Context);

  const syncCartCookie = async (count) => {
    if (count === 0) {
      CookieStorage.removeCookie({ name: CARTLINE_QUANTITIES_COOKIE_NAME });
      setTick(Date.now());
      return;
    }

    const cartLinesCookie = CookieStorage.getCookie(CARTLINE_QUANTITIES_COOKIE_NAME);
    const quantitiesMap = parseCartQuantities(cartLinesCookie || '');

    if (quantitiesMap.size !== count) {
      const { regionCode, pid, latLong, deviceType, store } = appCtx;
      const cartId = CookieStorage.getCookie('cartId');

      if (cartId) {
        const mkp = isMarketPlace();
        const headers = getCommerceHeaders({ isMarketPlace: mkp, regionCode, store });
        await httpService(appCtx).get(apiConfig.getCartCount(cartId, regionCode, pid, latLong), {
          headers: {
            ...headers,
            'x-channel-id': deviceType === DEVICE_DESKTOP ? 'WEB' : 'MOBILE',
          },
        });
      }
    }
    setTick(Date.now());
  };

  useEffect(() => {
    if (appCtx.USER_DATA_COOKIE) {
      const newCartCount = appCtx.USER_DATA_COOKIE.split('*')[1];
      setCartCount(newCartCount);

      if (isTottusStandalone(appCtx.store)) {
        syncCartCookie(parseInt(newCartCount || 0, 10));
      }
    }
  }, [appCtx.USER_DATA_COOKIE]);

  useEffect(() => {
    const handlePageShow = (event) => {
      if (isTottusStandalone(appCtx.store) && tick === 0) {
        const dataCookie = CookieStorage.getCookie('CATALYST_DATA_COOKIE');
        const count = parseInt(dataCookie?.split?.('*')?.[1] || 0, 10);

        syncCartCookie(count);
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  const currentLocation = _.get(appCtx, 'zoneName', '');

  const catalystBaseUrl = _.get(
    appCtx,
    'header.entry.header_configs[0].links.catalystbaseurl',
    'https://www.falabella.com'
  );

  const handleRouteChange = (termo, searchInSis) => {
    const SEARCH_PAGE = isStandalone({ store: appCtx.store }) ? 'SA_SEARCH_PAGE' : 'SEARCH_PAGE';
    if ((appCtx.store && searchInSis) || !appCtx.store) {
      if (!['/search', '/buscar', '/shop', '/b'].includes(router.route)) {
        if (!searchInSis) {
          const url = WithUrl(SEARCH_PAGE, appCtx.regionCode, {
            custom: {
              searchTerm: termo,
            },
          });
          redirect(url);
        } else {
          const url = WithUrl(
            SEARCH_PAGE,
            appCtx.regionCode,
            {
              custom: {
                searchTerm: termo,
              },
            },
            appCtx.store
          );
          redirect(url);
        }
      } else {
        navigateWithUrl({
          site: appCtx.tenant,
          url: `Ntt=${termo}`,
          store: appCtx.store,
          regionCode: appCtx.regionCode,
          onRouteChange: () => {
            WebStorage.setItem({
              name: constants.SESSION_STORAGE_NEW_PAGE,
              shouldPersist: false,
              value: 'true',
            });
            appCtx.setBusy(true);
          },
        });
      }
    } else {
      const url = WithUrl(SEARCH_PAGE, appCtx.regionCode, {
        custom: {
          searchTerm: termo,
        },
      });
      redirect(url);
    }
  };

  const onUserLogin = () => {
    refreshUserData();
  };

  return (
    <>
      <Head>
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-Black.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-BlackItalic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-Bold.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-BoldItalic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-Hairline.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-HairlineItalic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-Italic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-Light.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-LightItalic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-Regular.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/icons/fonts/sodimac.ttf?fu3jcb"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/icons/fonts/sodimac.woff?fu3jcb"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
      </Head>
      <Header
        currentLocation={currentLocation}
        data={{
          header: {
            ..._.get(appCtx, 'header'),
            transversalBannerHtml: _.get(appCtx, 'transversalBanner'),
          },
          sisNavigationMenu: _.get(appCtx, 'sisNavigationMenu'),
          taxonomy: _.get(appCtx, 'taxonomy'),
        }}
        isGeoLocationEnabled={_.get(appCtx, 'siteConfig.toggles.isGeoLocationEnabled', false)}
        isTypeAheadEnabled={_.get(appCtx, 'siteConfig.toggles.isTypeAheadEnabled', false)}
        isATGLoginEnabled={_.get(appCtx, 'siteConfig.toggles.isATGLoginEnabled', false)}
        isDefaultComuna={_.get(appCtx, 'isDefaultComuna', false)}
        selectedZone={_.get(appCtx, 'comuna.data', undefined)}
        onUserLogout={appCtx.logoutUser}
        onUserLogin={onUserLogin}
        enableAnalyticsInfoCall
        isZoneModalEnabled
        userCartCount={cartCount}
        parentRouter={Router}
        handleRouteChange={handleRouteChange}
        catalystBaseUrl={catalystBaseUrl}
        store={appCtx.store}
        handleSoftRefresh={appCtx.reloadOnZoneChange}
        isPopOverVisible={false}
        isWebPushNotificationEnabled={_.get(appCtx, 'siteConfig.toggles.isWebPushNotificationEnabled', false)}
        isOrangeHeader={appCtx.isOrangeHeader}
        isSISLevel2Header={appCtx.isSISLevel2Header}
        isFingerprintTrackingEnabled={_.get(appCtx, 'siteConfig.toggles.isFingerprintTrackingEnabled', true)}
        isGoogleTagManagerEnabled
        isSodimacStandAloneSite={isSodimacStandalone(appCtx.store)}
        isTottusStandAloneSite={isTottusStandalone(appCtx.store)}
      />
      <div className="main">{children}</div>
      {appCtx.busy > 0 && <Loader />}
      <Footer data={_.get(appCtx, 'footer')} store={appCtx.store} />
      <style jsx>{layoutCss}</style>
    </>
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.node,
  appCtx: PropTypes.object.isRequired,
};

LayoutContainer.defaultProps = {
  children: null,
};

export default LayoutContainer;
