import constants from '../../config/constants';
import { appendScriptsToHead, createScriptTag } from '../../utils/DOMUtil';

const loadGoogleAdScript = () => {
  const dynamicScript = createScriptTag({
    src: constants.GOOGLE_ADS,
    async: true,
  });
  appendScriptsToHead(dynamicScript);
};

export default loadGoogleAdScript;
